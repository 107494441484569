import React from 'react'

import BaseConsultsList from 'ui/views/consults/BaseConsultsList'
import analysisStatusConstants from 'utils/constants/analysisStatus'
import serviceStatusConstants from 'utils/constants/serviceStatus'

export default function ({ location }) {
  const title = 'Demorando a Processar'
  const subtitle = 'Consultas que estão demorando para processar'
  const status = serviceStatusConstants.SERVICE_STATUS.processing
  const color = '#ddd'
  const isTakingTimeToProcess = true
  const hasManualDerivation = false

  const arrayAnalysisStatus = [
    analysisStatusConstants.SERVICE_ANALYSIS_STATUS_MOVE_TO.approved,
    // analysisStatusConstants.SERVICE_ANALYSIS_STATUS_MOVE_TO.in_attention,
    analysisStatusConstants.SERVICE_ANALYSIS_STATUS_MOVE_TO.reproved,
    analysisStatusConstants.SERVICE_ANALYSIS_STATUS_MOVE_TO.blacklist,
  ]

  return (
    <BaseConsultsList
      location={location}
      title={title}
      subtitle={subtitle}
      color={color}
      actionsAnalysisStatus={arrayAnalysisStatus}
      status={status}
      isTakingTimeToProcess={isTakingTimeToProcess}
      hasManualDerivation={hasManualDerivation}
    />
  )
}
