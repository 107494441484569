import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import { TextField, Tooltip } from '@material-ui/core'
import { DialogConfirm, Icons } from 'ui/components'

import { formatDocNumber } from 'utils/masks'
import reportError from 'utils/errorReporter'
import analysisStatusConstants, { getAnalysisStatusMoveToName } from 'utils/constants/analysisStatus'
import { patchUpdateAnalysisStatus } from 'services/apiMotorBgc'

import styles from './styles'

const actions = [
  {
    type: analysisStatusConstants.SERVICE_ANALYSIS_STATUS_MOVE_TO.approved,
    color: '#19D315',
    icon: 'checkFilled',
  },
  {
    type: analysisStatusConstants.SERVICE_ANALYSIS_STATUS_MOVE_TO.reproved,
    color: '#E91010',
    icon: 'xCircle',
  },
  // TODO: desativado pois o comportamento esta incorreto.
  // veja mais na issues/1
  // {
  //   type: analysisStatusConstants.SERVICE_ANALYSIS_STATUS_MOVE_TO.blacklist,
  //   color: '#000000',
  //   icon: 'block',
  // },
]

export default ({ actionsAnalysisStatus, doc, onSuccess }) => {
  const classes = styles()

  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)
  const panelEnv = useSelector((state) => state.auth.panelEnv)

  const [dialogConfirmOpen, setDialogConfirmOpen] = useState(false)
  const [typeSelected, setTypeSelected] = useState(null)
  const [description, setDescription] = useState('')

  const descriptionValid = (desc) => {
    return _.isString(desc) && _.size(desc) > 2
  }

  const typeValid = (type) => {
    return _.isString(type)
  }

  const updateAnalysisStatus = async (newAnalysisStatus, desc) => {
    try {
      await patchUpdateAnalysisStatus(
        apiKey,
        userId,
        panelEnv,
        doc.doc_id,
        newAnalysisStatus,
        desc,
      )
    } catch (err) {
      reportError(err, 'Erro ao atualizar')
      return
    }

    setDialogConfirmOpen(false)
    onSuccess()
  }

  const handleClickAction = (event, type) => {
    event.stopPropagation()

    setTypeSelected(type)
    setDialogConfirmOpen(true)
  }

  const handleConfirm = () => {
    if (!typeValid(typeSelected) || !descriptionValid(description)) return

    updateAnalysisStatus(typeSelected, description)
  }

  const handleCancel = () => {
    setDialogConfirmOpen(false)
  }

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value)
  }

  const renderTooltipText = (type) => (
    `Alterar status para "${getAnalysisStatusMoveToName(type)}"`
  )

  const actionsFiltered = _.filter(
    actions,
    (action) => actionsAnalysisStatus.includes(action.type),
  )

  return (
    <React.Fragment>
      {_.map(actionsFiltered, action => (
        <Tooltip
          key={action.type}
          title={renderTooltipText(action.type)}
        >
          <div
            className="icon-button"
            onClick={(e) => handleClickAction(e, action.type)}
          >
            <Icons color={action.color} icon={action.icon} />
          </div>
          </Tooltip>
      ))}

      <DialogConfirm
        open={dialogConfirmOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        btnConfirmDisabled={!typeValid(typeSelected) || !descriptionValid(description)}
        title="Alterar Status"
        content={(
          <div className={classes.dialogContainerContent}>
            <p className={classes.dialogContentText}>
              <span>{`${panelEnv.toUpperCase()} `}</span>
              <strong>{formatDocNumber(doc[panelEnv], panelEnv)}</strong>
              <span>{`, você está alterando o status de `}</span>
              <strong>{getAnalysisStatusMoveToName(doc.analysis_status)}</strong>
              <span>{` para `}</span>
              <strong>{getAnalysisStatusMoveToName(typeSelected)}</strong>
            </p>
            <div className={classes.dialogContentContainerInput}>
              <TextField
                label="Motivo da alteração"
                placeholder="Descreva o motivo da alteração"
                fullWidth={true}
                multiline={true}
                required={true}
                rows={3}
                size="medium"
                onChange={handleDescriptionChange}
                inputProps={{
                  maxLength: 300,
                }}
              />
            </div>
          </div>
        )}
      />
    </React.Fragment>
  )
}
